import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { StyleProps } from '@backyard-ui/styles';

import {
  CheckmarkCircle,
  CheckmarkCircleOutline,
  AlertTriangle,
  InfoCircle,
  TimesCircle,
  AlertTriangleOutline,
  InfoCircleOutline,
  TimesCircleOutline,
} from '@backyard-ui/icons';

import { Spinner } from '../Spinner';

import { useAlertContext } from './Alert.context';

import styles from './Alert.styles';

const ICONS = {
  critical: {
    neutral: TimesCircle,
    subtle: TimesCircleOutline,
    solid: TimesCircleOutline,
  },
  info: {
    neutral: InfoCircle,
    subtle: InfoCircleOutline,
    solid: InfoCircleOutline,
  },
  success: {
    neutral: CheckmarkCircle,
    subtle: CheckmarkCircleOutline,
    solid: CheckmarkCircleOutline,
  },
  warning: {
    neutral: AlertTriangle,
    subtle: AlertTriangleOutline,
    solid: AlertTriangleOutline,
  },
};

export interface AlertIconProps
  extends StyleProps<HTMLAttributes<HTMLSpanElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLSpanElement>;

  /**
   * The children elements
   */
  children?: ReactNode;
}

const AlertIcon = React.forwardRef<ElementRef<'span'>, AlertIconProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, children, ...rest } = props;
    const { appearance, variant, isLoading } = useAlertContext();

    const Element = React.createElement(
      ICONS[appearance as keyof typeof appearance][
        variant as keyof typeof variant
      ]
    );

    return (
      <span
        className={styles({ appearance, variant, isLoading }).icon({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      >
        {isLoading ? <Spinner /> : children || Element}
      </span>
    );
  }
);

AlertIcon.displayName = '@backyard-ui/core/AlertIcon';

export default AlertIcon;
