import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { StyleProps } from '@backyard-ui/styles';

import styles from './Alert.styles';

export interface AlertDescriptionProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const AlertDescription = React.forwardRef<
  ElementRef<'div'>,
  AlertDescriptionProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;

  return (
    <div
      className={styles().description({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...rest}
    />
  );
});

AlertDescription.displayName = '@backyard-ui/core/AlertDescription';

export default AlertDescription;
