import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    pointer-events-none

    h-[var(--skeleton-height)]
    w-[var(--skeleton-width)]

    animate-pulse

    cursor-default
    select-none

    overflow-hidden

    bg-neutral-100

    [&_*]:invisible
  `,
  variants: {
    radius: {
      sm: 'rounded-sm',
      md: 'rounded',
      lg: 'rounded-md',
      xl: 'rounded-lg',
      '2xl': 'rounded-xl',
      full: 'rounded-full',
    },
  },
});

export default styles;
