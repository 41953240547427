import React from 'react';
import type { InputHTMLAttributes, ElementRef, Ref } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Slot } from '@radix-ui/react-slot';

import { useFormControl } from '../FormControl/use-form-control';
import type { FormControlOptions } from '../FormControl';

import { useTextFieldContext } from './TextField.context';

import styles from './TextField.styles';

export interface TextFieldInputProps
  extends StyleProps<InputHTMLAttributes<HTMLInputElement>>,
    FormControlOptions {
  /**
   *
   */
  asChild?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLInputElement>;
}

const TextFieldInput = React.forwardRef<
  ElementRef<'input'>,
  TextFieldInputProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, asChild, ...rest } = props;

  const Component = asChild ? Slot : 'input';

  const input = useFormControl(rest);
  const ctx = useTextFieldContext();

  return (
    <Component
      className={styles({ size: ctx?.size }).input({
        className: UNSAFE_className,
      })}
      style={UNSAFE_style}
      ref={ref}
      {...input}
    />
  );
});

TextFieldInput.displayName = '@backyard-ui/core/TextFieldInput';

export default TextFieldInput;
