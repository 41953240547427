import React from 'react';
import type {
  HTMLAttributes,
  ElementRef,
  Ref,
  ReactNode,
  CSSProperties,
} from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { filterFalsyChildren, mergeRefs } from '@backyard-ui/utils';

import { TextFieldProvider } from './TextField.context';

import styles from './TextField.styles';

export interface TextFieldProps
  extends StyleProps<Omit<HTMLAttributes<HTMLDivElement>, 'size'>> {
  /**
   * The Input size.
   *
   * @default sm
   */
  size?: 'xs' | 'sm' | 'md';

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const TextField = React.forwardRef<ElementRef<'div'>, TextFieldProps>(
  (props, ref) => {
    const {
      size = 'sm',
      UNSAFE_className,
      UNSAFE_style,
      children,
      ...rest
    } = props;

    const rootRef = React.useRef<HTMLDivElement>(null);
    const slotLRef = React.useRef<HTMLDivElement>();
    const slotRRef = React.useRef<HTMLDivElement>();

    let slotL: JSX.Element | null = null;
    let slotR: JSX.Element | null = null;
    let input: JSX.Element | null = null;
    let childs: JSX.Element | null = null;

    filterFalsyChildren(children).map((child: JSX.Element) => {
      if (child.type.displayName === '@backyard-ui/core/TextFieldInput') {
        return (input = child);
      }

      if (child.type.displayName === '@backyard-ui/core/TextFieldSlot') {
        if (slotL || input) {
          return (slotR = React.cloneElement(child, {
            key: 'slote-right',
            'data-slot': 'right',
            ref: (el: HTMLDivElement) => (slotRRef.current = el),
          }));
        }

        return (slotL = React.cloneElement(child, {
          key: 'slote-left',
          'data-slot': 'left',
          ref: (el: HTMLDivElement) => (slotLRef.current = el),
        }));
      }

      return (childs = child);
    });

    React.useLayoutEffect(() => {
      const getSlotsWidth = () => {
        const slotLWidth = slotLRef.current?.offsetWidth || 0;
        const slotRWidth = slotRRef.current?.offsetWidth || 0;

        if (typeof slotLWidth === 'number') {
          rootRef.current?.style.setProperty(
            '--text-field-slot-left-width',
            `${slotLWidth}px`
          );
        }

        if (typeof slotRWidth === 'number') {
          rootRef.current?.style.setProperty(
            '--text-field-slot-right-width',
            `${slotRWidth}px`
          );
        }
      };

      getSlotsWidth();

      const observer = new ResizeObserver(getSlotsWidth);

      if (slotLRef.current) {
        observer.observe(slotLRef.current, {
          box: 'border-box',
        });
      }

      if (slotRRef.current) {
        observer.observe(slotRRef.current, {
          box: 'border-box',
        });
      }

      return () => {
        observer.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [slotLRef.current, slotRRef.current]);

    return (
      <TextFieldProvider value={{ size }}>
        <div
          className={styles().base({
            className: UNSAFE_className,
          })}
          style={
            {
              '--text-field-slot-left-width': '0px',
              '--text-field-slot-right-width': '0px',
            } as CSSProperties
          }
          ref={mergeRefs(rootRef, ref)}
          {...rest}
        >
          <div>
            {slotL}
            {input}
            {slotR}
          </div>
          {childs}
        </div>
      </TextFieldProvider>
    );
  }
);

TextField.displayName = '@backyard-ui/core/TextField';

export default TextField;
