import React from 'react';
import type { ElementRef, ReactElement } from 'react';

import { Icon } from '../Icon';
import { Spinner } from '../Spinner';
import type { ButtonProps } from '../Button';

import styles from './IconButton.styles';

export interface IconButtonProps
  extends Omit<
    ButtonProps,
    'iconLeft' | 'iconRight' | 'children' | 'asChild' | 'isFullWidth'
  > {
  /**
   * A11y: A label that describes the button
   */
  ariaLabel?: string;

  /**
   * The icon to be used in the button.
   */
  icon: ReactElement;
}

const IconButton = React.forwardRef<ElementRef<'button'>, IconButtonProps>(
  (props, ref) => {
    const {
      ariaLabel,
      appearance = 'primary',
      radius = 'full',
      size = 'sm',
      social,
      variant = 'solid',
      isDisabled = false,
      isLoading = false,
      icon,
      UNSAFE_className,
      UNSAFE_style,
      ...rest
    } = props;

    return (
      <button
        aria-label={ariaLabel}
        className={styles({
          appearance,
          radius,
          size,
          social,
          variant,
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        disabled={isDisabled || isLoading}
        ref={ref}
        {...rest}
      >
        {isLoading ? (
          <Spinner size={size} data-testid="icon-button--spinner" />
        ) : (
          <Icon size={size} children={icon} data-testid="icon-button--icon" />
        )}
      </button>
    );
  }
);

IconButton.displayName = '@backyard-ui/core/IconButton';

export default IconButton;
