import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    z-50

    min-w-[320px]
    max-w-sm

    shadow-sm
    transition-opacity
  `,
  variants: {
    animation: {
      true: 'animate-toast-enter',
      false: 'animate-toast-leave',
    },
  },
});

export default styles;
