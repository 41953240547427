import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { Root } from '@radix-ui/react-label';
import type { LabelProps as RootProps } from '@radix-ui/react-label';

import { useFormControlContext } from '../FormControl/FormControl.context';

import styles from './Label.styles';

export interface LabelProps extends StyleProps<RootProps> {}

const Label = React.forwardRef<ElementRef<'label'>, LabelProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;
    const field = useFormControlContext();

    return (
      <Root
        className={styles({ className: UNSAFE_className })}
        style={UNSAFE_style}
        ref={ref}
        {...(field?.getLabelProps(rest, ref) ?? {
          ref,
          ...rest,
        })}
      />
    );
  }
);

Label.displayName = '@backyard-ui/core/Label';

export default Label;
