import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    gap-[var(--stack-spacing-initial)]
    sm:gap-[var(--stack-spacing-sm)]
    md:gap-[var(--stack-spacing-md)]
    lg:gap-[var(--stack-spacing-lg)]
    xl:gap-[var(--stack-spacing-xl)]
    2xl:gap-[var(--stack-spacing-2xl)]
  `,
  variants: {
    isGrow: {
      true: '[&_*]:grow',
    },
    isWrap: {
      true: 'flex-wrap',
    },
  },
});

export default styles;
