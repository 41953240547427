import { createContext } from '@backyard-ui/utils';
import type { AlertProps } from './Alert';

export interface AlertContext
  extends Pick<AlertProps, 'appearance' | 'variant' | 'isLoading'> {}

const { Provider: AlertProvider, useContext: useAlertContext } =
  createContext<AlertContext>({
    name: '@backyard-ui/core/AlertContext',
  });

export { AlertProvider, useAlertContext };
