import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    fixed
    inset-0

    z-50

    flex

    h-screen
    w-screen

    items-start
    justify-center

    overflow-auto
  `,
  slots: {
    close: `
      absolute
      right-6
      top-4
    `,
    content: `
      relative

      my-16
      flex
      w-full
      flex-col

      rounded-md
      bg-white

      px-6
      py-4

      shadow-sm
      outline-none

      will-change-transform
      animate-in
      duration-150

      data-[state=open]:animate-in
      data-[state=closed]:animate-out
      data-[state="open"]:slide-in-from-bottom-4
      data-[state=closed]:duration-150
      data-[state=open]:duration-300
      data-[state]:motion-reduce:animate-none
    `,
    title: `
      block
      pb-4

      font-semibold
      heading-lg
      md:heading-xl
    `,
    description: `
      block
      overflow-auto

      py-2
      text-md
      md:text-lg
    `,
    footer: `
      flex
      justify-end

      space-x-2
      pt-4
    `,
    portal: 'z-50',
  },
  variants: {
    size: {
      xs: {
        content: 'max-w-xs',
      },
      sm: {
        content: 'max-w-sm',
      },
      md: {
        content: 'max-w-md',
      },
      lg: {
        content: 'max-w-lg',
      },
      xl: {
        content: 'max-w-xl',
      },
      '2xl': {
        content: 'max-w-2xl',
      },
      '3xl': {
        content: 'max-w-4xl',
      },
      full: {
        content: 'max-w-full',
      },
    },
    isCentered: {
      true: {
        base: 'items-center',
      },
    },
    hasScroll: {
      true: {
        content: 'max-h-[calc(100%-theme("spacing.32"))]',
      },
    },
  },
});

export default styles;
