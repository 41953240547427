import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    relative
    overflow-hidden
  `,
  slots: {
    viewport: `
      h-full
      w-full

      rounded-[inherit]
    `,
    scrollbar: `
      flex

      touch-none
      select-none

      transition-colors
    `,
    thumb: `
      relative
      flex-1

      rounded-full

      bg-gray-200
    `,
  },
  variants: {
    orientation: {
      horizontal: {
        scrollbar: `
          h-2.5

          border-t
          border-t-transparent

          p-px
        `,
      },
      vertical: {
        scrollbar: `
          mr-0.5

          h-full
          w-2.5

          border-l
          border-l-transparent

          p-px
        `,
      },
    },
  },
});

export default styles;
