import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    relative
    w-full
  `,
  slots: {
    helper: `
      mt-1
      block

      text-md
    `,
    error: `
      mt-1
      block

      text-md
      text-critical-600
    `,
  },
});

export default styles;
