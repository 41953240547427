import { toast as toastFunc } from 'react-hot-toast';
import type { ToastOptions } from 'react-hot-toast';

import { CloseButton } from '../CloseButton';
import * as Alert from '../Alert';
import type { AlertProps } from '../Alert';

import styles from './Toast.styles';

export interface ToastProps
  extends Omit<ToastOptions, 'ariaProps' | 'className' | 'iconTheme' | 'style'>,
    Pick<AlertProps, 'appearance' | 'role' | 'variant' | 'isLoading'> {
  /**
   * The Toast title
   */
  title?: string;

  /**
   * The Toast title
   */
  description: string;

  /**
   * If `true`, shows a close button
   */
  isClosable?: boolean;
}

const createToast = (options: ToastProps): string => {
  return toastFunc.custom(
    (instance) => (
      <Alert.Root
        appearance={options.appearance}
        variant={options.variant || 'neutral'}
        aria-live="polite"
        role={instance.ariaProps.role}
        UNSAFE_className={styles({ animation: instance.visible })}
        isLoading={options.isLoading}
        data-testid="Toast-Alert"
      >
        <Alert.Icon>{options?.icon}</Alert.Icon>
        <Alert.Content>
          {options?.title && <Alert.Title>{options.title}</Alert.Title>}
          <Alert.Description>{options.description}</Alert.Description>
        </Alert.Content>
        {options.isClosable && (
          <CloseButton
            size="xs"
            data-testid="Toast-CloseButton"
            onClick={() => toastFunc.dismiss(instance.id)}
          />
        )}
      </Alert.Root>
    ),
    options
  );
};

const promise = async <T extends unknown>(
  promise: Promise<T>,
  msgs: {
    loading: Omit<ToastProps, 'id'>;
    success: Omit<ToastProps, 'id'>;
    critical: Omit<ToastProps, 'id'>;
  },
  options?: Omit<ToastProps, 'description' | 'title'>
) => {
  const id = createToast({
    isLoading: true,
    ...msgs.loading,
  });

  try {
    await promise;

    return createToast({
      id,
      appearance: 'success',
      isLoading: false,
      ...options,
      ...msgs.success,
    });
  } catch (error) {
    return createToast({
      id,
      appearance: 'critical',
      isLoading: false,
      ...options,
      ...msgs.critical,
    });
  }
};

const toast = (opts: ToastProps) => createToast(opts);

toast.custom = toastFunc.custom;
toast.dismiss = toastFunc.dismiss;
toast.remove = toastFunc.remove;
toast.promise = promise;

export default toast;
