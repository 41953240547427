import React from 'react';
import type { HTMLAttributes, Ref, ElementRef, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './TextField.styles';

export interface TextFieldSlotProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The element children
   */
  children: ReactNode;
}

const TextFieldSlot = React.forwardRef<ElementRef<'div'>, TextFieldSlotProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <div
        className={styles().slot({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

TextFieldSlot.displayName = '@backyard-ui/core/TextFieldSlot';

export default TextFieldSlot;
