import React from 'react';
import type { ElementRef } from 'react';

import { CrossOutline } from '@backyard-ui/icons';

import { IconButton } from '../IconButton';
import type { IconButtonProps } from '../IconButton';

export interface CloseButtonProps
  extends Omit<IconButtonProps, 'icon' | 'social'> {}

const CloseButton = React.forwardRef<ElementRef<'button'>, CloseButtonProps>(
  (props, ref) => {
    const {
      ariaLabel = 'Close',
      appearance = 'neutral',
      radius = 'xl',
      variant = 'ghost',
      ...rest
    } = props;

    return (
      <IconButton
        aria-label={ariaLabel}
        appearance={appearance}
        radius={radius}
        variant={variant}
        icon={<CrossOutline />}
        ref={ref}
        {...rest}
      />
    );
  }
);

CloseButton.displayName = '@backyard-ui/core/CloseButton';

export default CloseButton;
