import React from 'react';
import type {
  HTMLAttributes,
  CSSProperties,
  ElementRef,
  Ref,
  ReactNode,
} from 'react';

import { createVar } from '@backyard-ui/styles';
import type { Token, ResponsiveValue } from '@backyard-ui/styles';

import { widthToColumns, countToColumns } from './SimpleGrid.utils';
import { filterFalsyValue } from '@backyard-ui/utils';

import styles from './SimpleGrid.styles';

export interface SimpleGridProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The CSS `grid-auto-columns` property
   */
  autoColumns?: CSSProperties['gridAutoColumns'];

  /**
   * The CSS `grid-auto-flow` property
   */
  autoFlow?: CSSProperties['gridAutoFlow'];

  /**
   * The CSS `grid-auto-rows` property
   */
  autoRows?: CSSProperties['gridAutoRows'];

  /**
   * The column gap between the grid items
   *
   * @default 4
   */
  gapX?: Token<'spacing'>;

  /**
   * The row gap between the grid items
   *
   * @default 4
   */
  gapY?: Token<'spacing'>;

  /**
   * The CSS `grid-column` property
   */
  column?: CSSProperties['gridColumn'];

  /**
   * The CSS `grid-row` property
   */
  row?: CSSProperties['gridRow'];

  /**
   * The CSS `grid-template-columns` property
   */
  templateColumns?: CSSProperties['gridTemplateColumns'];

  /**
   * The CSS `grid-template-rows` property
   */
  templateRows?: CSSProperties['gridTemplateRows'];

  /**
   * The number of columns
   *
   * @default 12
   */
  columns?: ResponsiveValue<number> | number | undefined;

  /**
   * The width at which child elements will break into columns.
   */
  minChildWidth?: string;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const SimpleGrid = React.forwardRef<ElementRef<'div'>, SimpleGridProps>(
  (props, ref) => {
    const {
      column,
      row,
      autoFlow,
      autoRows,
      templateRows,
      columns = 12,
      autoColumns,
      minChildWidth,
      gapX = '4',
      gapY = '4',
      className,
      style,
      ...rest
    } = props;

    const columnsStyles = minChildWidth
      ? widthToColumns(minChildWidth)
      : countToColumns(columns);

    const getSpacing = (value: SimpleGridProps['gapX']) =>
      createVar(
        `spacing-${
          String(value).includes('.')
            ? String(value).replace('.', '\\.')
            : value
        }`
      );

    const dynamicStyles = filterFalsyValue({
      gridAutoColumns: autoColumns,
      gridColumn: column,
      gridRow: row,
      gridAutoFlow: autoFlow,
      gridAutoRows: autoRows,
      gridTemplateRows: templateRows,
      '--grid-gap-x': getSpacing(gapX),
      '--grid-gap-y': getSpacing(gapY),
      ...columnsStyles,
    }) as CSSProperties;

    return (
      <div
        className={styles({ className })}
        style={{
          ...dynamicStyles,
          ...style,
        }}
        ref={ref}
        {...rest}
      />
    );
  }
);

SimpleGrid.displayName = '@backyard-ui/core/SimpleGrid';

export default SimpleGrid;
