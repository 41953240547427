import { genResponsiveVars } from '@backyard-ui/styles';
import type { ResponsiveValue } from '@backyard-ui/styles';

export function widthToColumns(width: string | number) {
  return genResponsiveVars(
    width,
    'grid-cols',
    (value) => `repeat(auto-fit, minmax(${value}, 1fr))`
  );
}

export function countToColumns(
  columns: ResponsiveValue<number> | number | undefined
) {
  return genResponsiveVars(
    columns,
    'grid-cols',
    (value) => `repeat(${value}, minmax(0, 1fr))`
  );
}
