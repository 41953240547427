import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { StyleProps } from '@backyard-ui/styles';

import styles from './Alert.styles';

export interface AlertContentProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const AlertContent = React.forwardRef<ElementRef<'div'>, AlertContentProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;

    return (
      <div
        className={styles().content({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

AlertContent.displayName = '@backyard-ui/core/AlertContent';

export default AlertContent;
