import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { StyleProps } from '@backyard-ui/styles';

import { useAlertContext } from './Alert.context';

import styles from './Alert.styles';

export interface AlertTitleProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const AlertTitle = React.forwardRef<ElementRef<'div'>, AlertTitleProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style, ...rest } = props;
    const { appearance, variant } = useAlertContext();

    return (
      <div
        className={styles({ appearance, variant }).title({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      />
    );
  }
);

AlertTitle.displayName = '@backyard-ui/core/AlertTitle';

export default AlertTitle;
