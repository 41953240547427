import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { filterFalsyValue } from '@backyard-ui/utils';

import styles from './Skeleton.styles';

export interface SkeletonProps
  extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   * The CSS `width` property
   *
   * @default 100%
   */
  width?: string;

  /**
   * The CSS `height` property
   *
   * @default auto
   */
  height?: string;

  /**
   * The CSS `border-radius` property
   *
   * @default sm
   */
  radius?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';

  /**
   * If true, render the children
   *
   * @default false
   */
  isLoaded?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children?: ReactNode;
}

const Skeleton = React.forwardRef<ElementRef<'div'>, SkeletonProps>(
  (props, ref) => {
    const {
      width = '100%',
      height = 'auto',
      radius = 'sm',
      isLoaded = false,
      UNSAFE_className,
      UNSAFE_style,
      children,
      ...rest
    } = props;

    const dynamicStyles = filterFalsyValue({
      '--skeleton-height': height,
      '--skeleton-width': width,
    });

    if (isLoaded) {
      return <React.Fragment>{children}</React.Fragment>;
    }

    return (
      <div
        className={styles({ radius, className: UNSAFE_className })}
        style={{ ...dynamicStyles, ...UNSAFE_style }}
        ref={ref}
        {...rest}
      >
        {children}
      </div>
    );
  }
);

Skeleton.displayName = 'Skeleton';

export default Skeleton;
