import { createContext } from '@backyard-ui/utils';

import type { TextFieldProps } from './TextField';

export interface TextFieldContext extends Pick<TextFieldProps, 'size'> {}

const { Provider: TextFieldProvider, useContext: useTextFieldContext } =
  createContext<TextFieldContext>({
    name: '@backyard-ui/core/TextFieldContext',
    strict: false,
  });

export { TextFieldProvider, useTextFieldContext };
