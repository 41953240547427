import React from 'react';
import type { HTMLAttributes, Ref, RefObject } from 'react';

import { useId } from '@backyard-ui/hooks';

import { createContext } from '@backyard-ui/utils';
import type { FormControlOptions } from './FormControl';

export interface FormControlContext extends FormControlOptions {
  /**
   * The label is used to describe the form element purpose.
   */
  label?: string;

  /**
   * The custom `id` to use for the form control.
   * This is passed directly to the form element (e.g, Input).
   * If not defined, a randomly generated id is used.
   */
  id?: string;
}

export type Callback<T extends HTMLElement = any> = (
  props?: HTMLAttributes<T> & {
    htmlFor?: string;
  },
  ref?: Ref<any> | RefObject<any>
) => HTMLAttributes<T>;

const { Provider: FormControlProvider, useContext: useFormControlContext } =
  createContext<
    Omit<
      ReturnType<typeof useFormControlProvider>,
      'getRootProps' | 'htmlProps'
    >
  >({
    name: '@backyard-ui/core/FormControlContext',
    strict: false,
  });

/**
 * React hook that provides the props `isInvalid`, `isDisabled`,
 * `isReadOnly` and `isRequired` to form elements.
 *
 * It provides some helpers to `Label`, `HelpText` and `ErrorMessage`.
 */
function useFormControlProvider(props: FormControlContext) {
  const {
    id: idProp,
    isDisabled,
    isInvalid,
    isReadOnly,
    isRequired,
    ...htmlProps
  } = props;

  const uuid = useId();
  const id = idProp || `field-${uuid}`;

  const labelId = `label-${id}`;
  const feedbackId = `feedback-${id}`;
  const helpTextId = `helptext-${id}`;

  const getRootProps = React.useCallback<Callback>(
    (props = {}, ref = null) => ({
      ...props,
      ...htmlProps,
      ref,
      role: 'group',
    }),
    [htmlProps]
  );

  const getLabelProps = React.useCallback<Callback>(
    (props = {}, ref = null) => {
      return {
        ...props,
        id: props.id ?? labelId,
        htmlFor: props.htmlFor ?? id,
        ref,
      };
    },
    [id, labelId]
  );

  const getHelpTextProps = React.useCallback<Callback>(
    (props = {}, ref = null) => ({
      id: helpTextId,
      ref,
      ...props,
    }),
    [helpTextId]
  );

  const getErrorMessageProps = React.useCallback<Callback>(
    (props = {}, ref = null) => ({
      id: feedbackId,
      ref,
      ...props,
    }),
    [feedbackId]
  );

  return {
    id,
    feedbackId,
    helpTextId,
    labelId,
    htmlProps,
    getRootProps,
    getLabelProps,
    getHelpTextProps,
    getErrorMessageProps,
    isDisabled: Boolean(isDisabled),
    isInvalid: Boolean(isInvalid),
    isReadOnly: Boolean(isReadOnly),
    isRequired: Boolean(isRequired),
  };
}

export { FormControlProvider, useFormControlContext, useFormControlProvider };
