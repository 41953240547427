import React from 'react';
import type { ElementRef, Ref, ReactNode } from 'react';

import { createVar, genResponsiveVars } from '@backyard-ui/styles';
import type { Token, ResponsiveValue } from '@backyard-ui/styles';

import { filterFalsyChildren } from '@backyard-ui/utils';

import { Flex } from '../Flex';
import type { FlexProps } from '../Flex';

import styles from './Stack.styles';

export interface StackProps
  extends Omit<FlexProps, 'basis' | 'flex' | 'grow' | 'shrink' | 'wrap'> {
  /**
   * The space between its children
   *
   * @default 4
   */
  spacing?: ResponsiveValue<Token<'spacing'>>;

  /**
   * If true the component will occupy remaining space
   * by setting 'flex-grow: 1'
   *
   * @default false
   */
  isGrow?: boolean;

  /**
   * The CSS `flex-wrap` property
   *
   * @default false
   */
  isWrap?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Stack = React.forwardRef<ElementRef<'div'>, StackProps>((props, ref) => {
  const {
    direction = 'row',
    spacing = '4',
    className,
    style,
    isGrow = false,
    isWrap = false,
    children,
    ...rest
  } = props;

  const dynamicStyles = genResponsiveVars(spacing, 'stack-spacing', (value) =>
    createVar(
      `spacing-${
        String(value).includes('.') ? String(value).replace('.', '\\.') : value
      }`
    )
  );

  return (
    <Flex
      direction={direction}
      className={styles({ isGrow, isWrap, className })}
      style={{ ...dynamicStyles, ...style }}
      ref={ref}
      {...rest}
    >
      {filterFalsyChildren(children).map((child, index) => (
        <React.Fragment key={index}>{child}</React.Fragment>
      ))}
    </Flex>
  );
});

Stack.displayName = '@backyard-ui/core/Stack';

export default Stack;
