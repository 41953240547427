import React from 'react';
import type { HTMLAttributes, ElementRef, Ref } from 'react';

import styles from './Overlay.styles';

export interface OverlayProps extends HTMLAttributes<HTMLDivElement> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;
}

const Overlay = React.forwardRef<ElementRef<'div'>, OverlayProps>(
  (props, ref) => {
    const { className, ...rest } = props;

    return <div className={styles({ className })} ref={ref} {...rest} />;
  }
);

Overlay.displayName = '@backyard-ui/core/Overlay';

export default Overlay;
