import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { useFormControlContext } from './FormControl.context';

import styles from './FormControl.styles';

export interface FormErrorMessageProps
  extends StyleProps<HTMLAttributes<HTMLSpanElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLSpanElement>;

  /**
   * The span children
   */
  children: ReactNode;
}

const FormErrorMessage = React.forwardRef<
  ElementRef<'span'>,
  FormErrorMessageProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;
  const field = useFormControlContext();

  if (!field?.isInvalid) {
    return null;
  }

  return (
    <span
      className={styles().error({ className: UNSAFE_className })}
      style={UNSAFE_style}
      {...field?.getErrorMessageProps(rest, ref)}
    />
  );
});

FormErrorMessage.displayName = '@backyard-ui/core/FormErrorMessage';

export default FormErrorMessage;
