import { useFormControlContext } from './FormControl.context';
import type { FormControlOptions } from './FormControl';

export interface UseFormControlProps extends FormControlOptions {
  id?: string;
  disabled?: boolean;
  readOnly?: boolean;
  required?: boolean;
}

/**
 * React hook that normalize the props from context.
 */
export function useFormControlProps(props: UseFormControlProps) {
  const field = useFormControlContext();

  const {
    id,
    disabled,
    readOnly,
    required,
    isInvalid,
    isDisabled,
    isReadOnly,
    isRequired,
    ...rest
  } = props;

  return {
    ...rest,
    id: id ?? field?.id,
    isInvalid: isInvalid ?? field?.isInvalid,
    isDisabled: disabled ?? isDisabled ?? field?.isDisabled,
    isReadOnly: readOnly ?? isReadOnly ?? field?.isReadOnly,
    isRequired: required ?? isRequired ?? field?.isRequired,
  };
}

/**
 * React hook that provides common props between form elements.
 */
export function useFormControl(props: UseFormControlProps) {
  const { isInvalid, isDisabled, isReadOnly, isRequired, ...rest } =
    useFormControlProps(props);

  return {
    ...rest,
    disabled: isDisabled,
    readOnly: isReadOnly,
    required: isRequired,
    'data-invalid': isInvalid || undefined,
    'aria-invalid': isInvalid || undefined,
    'aria-required': isRequired || undefined,
    'aria-readonly': isReadOnly || undefined,
  };
}
