import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    relative

    flex
    w-full
    items-start
    gap-2

    overflow-hidden
    rounded-md

    px-4
    py-3

    shadow-xs

    transition-colors
    duration-100
    ease-in
    will-change-transform
  `,
  slots: {
    title: `
      text-md
      font-semibold
    `,
    description: `
      flex

      break-words
      text-md
    `,
    content: 'flex-1',
    icon: `
      mt-0.5
      block

      h-4
      w-4
      shrink-0

      [&_svg]:block
      [&_svg]:h-4
      [&_svg]:w-4
    `,
  },
  variants: {
    appearance: {
      critical: '',
      info: '',
      success: '',
      warning: '',
    },
    variant: {
      neutral: {
        base: `
        bg-white
        text-neutral-900
        `,
      },
      solid: '',
      subtle: '',
    },
    isLoading: {
      true: {
        base: 'bg-white',
        icon: 'text-info-600',
      },
    },
  },
  compoundVariants: [
    // neutral
    {
      appearance: 'critical',
      variant: 'neutral',
      isLoading: false,
      className: {
        icon: 'text-critical-600',
      },
    },
    {
      appearance: 'info',
      variant: 'neutral',
      isLoading: false,
      className: {
        icon: 'text-info-600',
      },
    },
    {
      appearance: 'success',
      variant: 'neutral',
      isLoading: false,
      className: {
        icon: 'text-success-600',
      },
    },
    {
      appearance: 'warning',
      variant: 'neutral',
      isLoading: false,
      className: {
        icon: 'text-warning-600',
      },
    },
    // solid
    {
      appearance: 'critical',
      variant: 'solid',
      isLoading: false,
      className: {
        base: `
          bg-critical-600
          text-white
        `,
      },
    },
    {
      appearance: 'info',
      variant: 'solid',
      isLoading: false,
      className: {
        base: `
          bg-info-600
          text-white
        `,
      },
    },
    {
      appearance: 'success',
      variant: 'solid',
      isLoading: false,
      className: {
        base: `
          bg-success-600
          text-white
        `,
      },
    },
    {
      appearance: 'warning',
      variant: 'solid',
      isLoading: false,
      className: {
        base: `
          bg-warning-500
          text-neutral-900
        `,
      },
    },
    // subtle
    {
      appearance: 'critical',
      variant: 'subtle',
      isLoading: false,
      className: {
        base: `
          bg-critical-100
          text-neutral-900
        `,
        icon: 'text-critical-600',
      },
    },
    {
      appearance: 'info',
      variant: 'subtle',
      isLoading: false,
      className: {
        base: `
          bg-info-100
          text-neutral-900
        `,
        icon: 'text-info-600',
      },
    },
    {
      appearance: 'success',
      variant: 'subtle',
      isLoading: false,
      className: {
        base: `
          bg-success-100
          text-neutral-900
        `,
        icon: 'text-success-600',
      },
    },
    {
      appearance: 'warning',
      variant: 'subtle',
      isLoading: false,
      className: {
        base: `
          bg-warning-100
          text-neutral-900
        `,
        icon: 'text-warning-600',
      },
    },
  ],
});

export default styles;
