import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    mb-1
    block

    text-md
    font-bold
  `,
});

export default styles;
