import { tv } from 'tailwind-variants';

const styles = tv({
  base: 'relative',
  slots: {
    input: `
      inline-flex

      h-auto
      w-full

      appearance-none

      items-center
      justify-center

      rounded-lg
      border
      border-gray-300

      bg-white
      text-neutral-900

      outline-none

      transition
      duration-100
      ease-in

      placeholder:text-neutral-300
      hover:border-gray-400

      focus:border-primary-600
      focus:ring-2
      focus:ring-primary-300

      read-only:bg-neutral-100

      disabled:pointer-events-none
      disabled:bg-neutral-100
      disabled:text-neutral-300

      data-[invalid]:border-critical-600
      data-[invalid]:focus:ring-critical-300
    `,
    slot: `
      absolute
      top-0

      flex
      h-full

      items-center
      justify-center

      px-3

      data-[slot='left']:left-0
      data-[slot='right']:right-0

      [&:not(button)>svg]:h-4
      [&:not(button)>svg]:w-4
    `,
  },
  variants: {
    size: {
      xs: {
        input: `
          h-7
          pl-[max(theme('spacing.3'),var(--text-field-slot-left-width))]
          pr-[max(theme('spacing.3'),var(--text-field-slot-right-width))]

          text-sm
        `,
      },
      sm: {
        input: `
          h-10
          pl-[max(theme('spacing.4'),var(--text-field-slot-left-width))]
          pr-[max(theme('spacing.4'),var(--text-field-slot-right-width))]

          text-lg
        `,
      },
      md: {
        input: `
          h-12
          pl-[max(theme('spacing.6'),var(--text-field-slot-left-width))]
          pr-[max(theme('spacing.6'),var(--text-field-slot-right-width))]

          text-xl
        `,
      },
    },
  },
});

export default styles;
