import React from 'react';
import type { HTMLAttributes, ElementRef, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import styles from './FormControl.styles';

import {
  FormControlProvider,
  useFormControlProvider,
} from './FormControl.context';
import type { FormControlContext } from './FormControl.context';

export interface FormControlOptions {
  /**
   * Set the form element to disabled
   */
  isDisabled?: boolean;

  /**
   * Set the form element `aria-invalid` to `true`
   */
  isInvalid?: boolean;

  /**
   * Set the form element to readonly
   */
  isReadOnly?: boolean;

  /**
   * Set the form element `aria-required` to `true`
   */
  isRequired?: boolean;
}

export interface FormControlProps
  extends FormControlContext,
    StyleProps<HTMLAttributes<HTMLDivElement>> {
  children?: ReactNode;
}

/**
 * FormControl provides context such as
 * `isInvalid`, `isDisabled`, `isReadOnly` and `isRequired` to form elements.
 */
const FormControl = React.forwardRef<ElementRef<'div'>, FormControlProps>(
  (props, ref) => {
    const { UNSAFE_className, UNSAFE_style } = props;
    const { getRootProps, ...context } = useFormControlProvider(props);

    return (
      <FormControlProvider value={context}>
        <div
          className={styles().base({ className: UNSAFE_className })}
          style={UNSAFE_style}
          ref={ref}
          {...getRootProps({}, ref)}
        />
      </FormControlProvider>
    );
  }
);

FormControl.displayName = 'FormControl';

export default FormControl;
