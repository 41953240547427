import { tv } from 'tailwind-variants';
import { default as base } from '../Button/Button.styles';

const styles = tv({
  extend: base,
  variants: {
    size: {
      xs: `
        h-7
        px-1.5
      `,
      sm: `
        h-10
        px-2.5
      `,
      md: `
        h-12
        px-3
      `,
    },
  },
});

export default styles;
