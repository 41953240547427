import React from 'react';

import { useEnhancedEffect } from '../use-enhanced-effect';

const useReactId = (React as any)['useId'.toString()] || (() => undefined);
let count = 0;

function useId(deterministicId?: string): string {
  const [id, setId] = React.useState<string | undefined>(useReactId());

  useEnhancedEffect(() => {
    if (!deterministicId) {
      setId((reactId) => reactId ?? String(count++));
    }
  }, [deterministicId]);

  return deterministicId || (id ? `backyard-ui-${id}` : '');
}

export default useId;
