import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import { useFormControlContext } from './FormControl.context';

import styles from './FormControl.styles';

export interface FormHelperTextProps
  extends StyleProps<HTMLAttributes<HTMLSpanElement>> {
  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLSpanElement>;

  /**
   * The label children
   */
  children: ReactNode;
}

const FormHelperText = React.forwardRef<
  ElementRef<'span'>,
  FormHelperTextProps
>((props, ref) => {
  const { UNSAFE_className, UNSAFE_style, ...rest } = props;
  const field = useFormControlContext();

  return (
    <span
      className={styles().helper({ className: UNSAFE_className })}
      style={UNSAFE_style}
      {...field?.getHelpTextProps(rest, ref)}
    />
  );
});

FormHelperText.displayName = '@backyard-ui/core/FormHelperText';

export default FormHelperText;
