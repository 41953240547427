import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    grid

    w-full

    grid-cols-[var(--grid-cols-initial)]
    gap-x-[var(--grid-gap-x)]
    gap-y-[var(--grid-gap-y)]

    sm:grid-cols-[var(--grid-cols-sm)]
    md:grid-cols-[var(--grid-cols-md)]
    lg:grid-cols-[var(--grid-cols-lg)]
    xl:grid-cols-[var(--grid-cols-xl)]
    2xl:grid-cols-[var(--grid-cols-2xl)]
  `,
});

export default styles;
