import React from 'react';
import type { ElementRef } from 'react';

import type { StyleProps } from '@backyard-ui/styles';

import {
  Root,
  Viewport,
  Scrollbar,
  Thumb,
  Corner,
} from '@radix-ui/react-scroll-area';
import type {
  ScrollAreaProps as RootProps,
  ScrollAreaScrollbarProps,
} from '@radix-ui/react-scroll-area';

import styles from './ScrollArea.styles';

export interface ScrollAreaProps
  extends StyleProps<Omit<RootProps, 'asChild'>>,
    Pick<ScrollAreaScrollbarProps, 'orientation'> {}

const ScrollAreaRoot = React.forwardRef<ElementRef<'div'>, ScrollAreaProps>(
  (props, ref) => {
    const {
      orientation = 'vertical',
      UNSAFE_className,
      UNSAFE_style,
      children,
      ...rest
    } = props;

    return (
      <Root
        className={styles().base({ className: UNSAFE_className })}
        style={UNSAFE_style}
        ref={ref}
        {...rest}
      >
        <Viewport className={styles().viewport()}>{children}</Viewport>

        <Scrollbar className={styles({ orientation }).scrollbar()}>
          <Thumb className={styles().thumb()} />
        </Scrollbar>
        <Corner />
      </Root>
    );
  }
);

ScrollAreaRoot.displayName = '@backyard-ui/core/ScrollArea';

export default ScrollAreaRoot;
