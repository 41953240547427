import { tv } from 'tailwind-variants';

const styles = tv({
  base: `
    fixed
    inset-0

    z-50

    bg-black-alpha-500

    transition-opacity
    animate-in
    fade-in
    duration-75
  `,
});

export default styles;
