import React from 'react';
import type { HTMLAttributes, ElementRef, Ref, ReactNode } from 'react';

import { StyleProps } from '@backyard-ui/styles';

import { AlertProvider } from './Alert.context';

import styles from './Alert.styles';

export interface AlertProps extends StyleProps<HTMLAttributes<HTMLDivElement>> {
  /**
   *  The alert appearance.
   *
   * @default success
   */
  appearance?: 'critical' | 'info' | 'success' | 'warning';

  /**
   * The variant is used
   * to change the visual communication.
   *
   * @default solid
   */
  variant?: 'neutral' | 'solid' | 'subtle';

  /**
   * The role of the alert
   *
   * @default alert
   */
  role?: 'status' | 'alert';

  /**
   * Show loading indicator
   */
  isLoading?: boolean;

  /**
   * The ref to the HTML DOM element
   */
  ref?: Ref<HTMLDivElement>;

  /**
   * The children elements
   */
  children: ReactNode;
}

const Alert = React.forwardRef<ElementRef<'div'>, AlertProps>((props, ref) => {
  const {
    appearance = 'success',
    variant = 'solid',
    role = 'alert',
    isLoading = false,
    UNSAFE_className,
    UNSAFE_style,
    ...rest
  } = props;

  return (
    <AlertProvider
      value={{
        appearance,
        variant,
        isLoading,
      }}
    >
      <div
        className={styles({ appearance, variant, isLoading }).base({
          className: UNSAFE_className,
        })}
        style={UNSAFE_style}
        role={role}
        ref={ref}
        {...rest}
      />
    </AlertProvider>
  );
});

Alert.displayName = '@backyard-ui/core/Alert';

export default Alert;
